body,
html,
#root {
  height: 100%;
  margin: 0;
  font-family: 'Roboto';
}

body {
  font-family: 'Roboto';
  --acrodat_white: #ffffff;
  --acrodat_orange_form_bg: #fed69a98;
  --acrodat_orange_ligth: #faaf40;
  --acrodat_orange_medium: #f6921e;
  --acrodat_orange: #f05a28;
  --acrodat_grey_inactive: #9a9697;
  --acrodat_bG_sB: #58585a;
  --acrodat_button_hover: #e37a00;
  --acrodat_link: #6f6f6f;
  --acrodat_link_hover: #f69225;

  --buttonIgro_yellow: #f8d42f;
  --igro_bG_sB: #0e5284;
  --igro_yellow: #f8d42f;
  --igro_blue_ligth: #1489d5;
  --igro_blue_medium: #195c88;
  --igro_blue_button: #127dc2;
  --igro_blue_button_hover: #0e6fad;
  --igro_menu_color: #aeb5bd;
  --igro_white: #ffffff;
  --igro_link: #aeb5bd;
}

.rowOfPage {
  margin: 0 !important;
  padding: 30px !important;
}

.titleChartPage {
  text-transform: uppercase;
  color: var(--acrodat_orange_medium);
  font-size: 20px;
  font-weight: bold;
}

.titleChartPageIgro {
  text-transform: uppercase;
  color: var(--igro_blue_medium);
  font-size: 20px;
  font-weight: bold;
}

.chartContainer {
  margin: 10px 0 40px 0;
  background-color: #fff;
  -webkit-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.5);
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.5);
  padding: 10px 20px;
}

svg {
  display: inline-block;
  vertical-align: middle;
}

.icon {
  color: var(--acrodat_link);
}

.icon:hover {
  color: var(--acrodat_link_hover);
}

.requiredAsterisk {
  color: #dc3545;
}

.mandatoryMessage {
  font-style: italic;
  color: #6f6f6f;
}

textarea {
  resize: none;
}

.headerTable {
  text-transform: uppercase;
  background-color: #ffd707;
}

.view-column-short {
  width: 11% !important;
}

.react-bootstrap-table .dropdown {
  display: none !important;
}

.actions-container {
  display: flex;
  flex-direction: row-reverse;
}

.buttonDownload {
  color: var(--acrodat_white);
  background-color: var(--acrodat_orange_medium);
  border-color: var(--acrodat_orange_medium);
  font-weight: 700;
  height: 56px;
  border-radius: 0;
  text-transform: uppercase;
  min-width: 120px;
}

.buttonDownload:hover,
.buttonDownload.focus,
.buttonDownload:focus {
  background-color: var(--acrodat_button_hover);
  border-color: var(--acrodat_button_hover);
}

.buttonDownload.disabled,
.buttonDownload:disabled {
  color: var(--acrodat_white) !important;
  background-color: var(--acrodat_orange_medium) !important;
  border-color: var(--acrodat_orange_medium) !important;
}

.buttonDownloadIgro {
  color: var(--igro_white);
  background-color: var(--igro_blue_button);
  border-color: var(--igro_blue_button);
  font-weight: 700;
  height: 56px;
  border-radius: 0;
  text-transform: uppercase;
  min-width: 120px;
}

.buttonDownloadIgro:hover,
.buttonDownloadIgro.focus,
.buttonDownloadIgro:focus {
  color: var(--igro_white);
  background-color: var(--igro_blue_button_hover);
  border-color: var(--igro_blue_button_hover);
}

.buttonDownloadIgro.disabled,
.buttonDownloadIgro:disabled {
  color: var(--igro_white) !important;
  background-color: var(--igro_blue_button) !important;
  border-color: var(--igro_blue_button) !important;
}

#chartTab-tabpane-table .table-actions-container {
  width: 100%;
  margin-top: -70px;
}
