.spinner-container {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.spinner-container.message {
  margin-top: 16px;
}
